import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(client)/(index)/components/index-faq.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(client)/(index)/components/index-features.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IndexFinalCut"] */ "/vercel/path0/src/app/(client)/(index)/components/index-finalcut.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(client)/(index)/components/index-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(client)/(index)/components/index-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(client)/(index)/components/index-heading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(client)/(index)/components/index-pricing.tsx");
