"use client"

import {generateCheckout, runCheckout} from '@/lib/stripe-utils'
import { useState } from 'react'

export default function IndexHeading() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [email,setEmail] = useState<string|undefined>(undefined)

    

    return (
        <>
            <div className={"mt-8"}>
                <div
                    className={"hidden md:block max-w-3xl bg-emerald-300 py-1 pl-2 pr-2 md:pl-8 mb-4 rounded-xl md:rounded-full bg-opacity-80"}>
                    <p>
                        <b>Limited Offer ― </b>
                        Lifetime access for just
                        <b> <s>$129</s></b>
                        <b> $89. </b>
                        One time payment.
                        <b> Pay once. Use forever.</b>
                    </p>
                </div>
                <p className={"text-3xl md:text-5xl font-mono font-bold"}>File handling that just works.</p>
                <p className={"text-xl md:text-xl mt-2 font-mono font-medium"}>Upload, store, and deliver files to
                    billions of users.</p>
                <div>
                    <form className={"flex gap-x-4 mt-8"} onSubmit={(e) => {
                        e.preventDefault()
                        runCheckout(email ?? undefined)
                    }}>
                        <div className={`h-[48px] max-w-lg flex gap-x-2 w-full rounded-lg border border-input bg-transparent px-2 lg:px-4 py-3 transition-all placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 items-center`}>
                                    <input required={true} value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="email" placeholder="Enter your email address" className={`w-full text-md text-black bg-transparent outline-none focus:outline-none border-0`}/>
                                </div>
                                <button data-umami-event="IndexHeading: Sign_Up_BUTTON" type={"submit"}
                                        className={"font-bold truncate w-40 hover:opacity-60 transition-all hover:animate-pulse text-md bg-black text-white p-1 mt-0 px-3 rounded-lg "}>
                                    Sign Up
                                </button>
                    </form>
                </div>
            </div>
            <video className={"mr-auto mt-10 rounded-xl border-4 border-black drop-shadow-2xl"} autoPlay={true}
                   playsInline={true} loop={true} muted={true} controls={false} width="100%"
//                   poster="https://pub-ea6abbe90d8d4796ba06e024824ceab6.r2.dev/spring-13.jpg"
                >
                <source src="https://pub-ea6abbe90d8d4796ba06e024824ceab6.r2.dev/Area.mp4" type="video/mp4"/>
            </video>
        </>
        )
}
