"use client"

import {useState} from 'react'
import {PersonIcon} from "@radix-ui/react-icons";


const features = [
    {
        icon: PersonIcon,
        title: "Visitor Info",
        description: "Get detailed information about your visitors like their device, browser, OS and location."
    }
]

const privacy = [
    {
        icon: PersonIcon,
        title: "Visitor Info",
        description: "Get detailed information about your visitors like their device, browser, OS and location."
    }
]

const commitments = [
    {
        icon: PersonIcon,
        title: "Visitor Info",
        description: "Get detailed information about your visitors like their device, browser, OS and location."
    }
]

export default function IndexFeatures() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div id={"features"} className={"mb-40 mt-20 gap-y-32 grid"}>
            <div>
                <p className={"text-3xl md:text-5xl font-mono font-bold"}>Bring-Your-Own-Bucket</p>
                <p className={"text-xl md:text-xl mt-2 font-mono font-medium"}>The best way to store files is in your own storage bucket. You benefit from uploadWorks features, while having full uninterrupted access to your files.<br/>Amazon S3 and Cloudflare R2 supported.</p>
            </div>
            <div className={"lg:flex justify-between gap-x-4 gap-y-10 grid"}>
                <div>
                    <p className={"text-3xl md:text-5xl font-mono font-bold"}>Signed Safety</p>
                    <p className={"text-xl md:text-xl mt-2 font-mono font-medium"}>Generate client-safe signed URLs to upload images, and include parameters to specify custom warnings, file type, size, and more.</p>
                </div>
                <div className={"mx-auto bg-emerald-400 w-48 h-48 flex rounded-xl"}>
                    <svg className={"w-32 m-auto fill-sky-900"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 224a224 224 0 1 1 448 0A224 224 0 1 1 0 224zM248 96H200v24V224v9.9l7 7 48 48 17 17L305.9 272l-17-17-41-41V120 96zM224 480c96.4 0 180.3-53.3 224-132V512H0V348c43.7 78.7 127.6 132 224 132z"/></svg>
                </div>
            </div>
            <div className={"lg:flex justify-between gap-x-4 gap-y-10 grid"}>
                <div>
                    <p className={"text-3xl md:text-5xl mt-5 font-mono font-bold"}>Save Time and Money</p>
                    <p className={"text-xl md:text-xl mt-2  font-mono font-medium"}>
                        Custom image handling takes time, money, and more time.
                        <br/>
                        Get up and running in 10 minutes - and never look back.
                    </p>
                </div>
                <div className={"mx-auto bg-emerald-400 w-48 h-48 flex rounded-xl"}>
                    <svg className={"w-32 m-auto fill-sky-900"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 224a224 224 0 1 1 448 0A224 224 0 1 1 0 224zM248 96H200v24V224v9.9l7 7 48 48 17 17L305.9 272l-17-17-41-41V120 96zM224 480c96.4 0 180.3-53.3 224-132V512H0V348c43.7 78.7 127.6 132 224 132z"/></svg>
                </div>
            </div>
        </div>
        )
}
