"use client"

import { runCheckout } from "@/lib/stripe-utils";

export function IndexFinalCut() {
    return (
        <>
        <div className={"mt-40 mb-20 text-center"}>
            <p className={"text-3xl md:text-4xl font-mono font-black"}>Files are hard, we just make them easy.</p>
            <p className={"text-xl md:text-xl mt-2 font-mono font-medium"}>Add safe file uploading to your website in minutes, not days.</p>
            <div className={"w-min truncate mx-auto mt-4"}>
                <button data-umami-event="IndexFinalCut: Sign_Up_BUTTON" onClick={(e)=>runCheckout(undefined)} className={"hover:opacity-60 transition-all hover:animate-pulse font-medium text-md bg-emerald-400 h-12 text-black p-1 mt-0 px-3 rounded-lg "}>
                    Sign Up ―
                    <a className={"font-light italic ml-1"}>start uploading!</a>
                </button>
            </div>
        </div>
        </>
    )
}