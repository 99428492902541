"use client"

import {
    BookIcon,
    CookieIcon,
    HeartHandshakeIcon,
    PaperclipIcon,
    PhoneIcon,
    PiggyBankIcon
} from "lucide-react"

// @ts-ignore
const navigation = {
  main: [
    { name: 'Docs', href: '/docs', icon: PaperclipIcon},
    { name: 'Changes', href: '/changelog', icon: BookIcon},
    { name: 'Refunds', href: 'mailto:help@upload.work', icon: PiggyBankIcon},
    { name: 'Terms of Use', href: '/legal/terms', icon: HeartHandshakeIcon},
    { name: 'Privacy', href: '/legal/privacy', icon: CookieIcon},
    { name: 'Contact Us', href: '/help', icon: PhoneIcon},
  ]
}

export default function IndexFooter() {
  return (
    <footer suppressHydrationWarning={true}>
      <div className="mx-auto max-w-7xl overflow-hidden px-6 pt-20 sm:py-24 lg:px-8">
        <nav className="-mb-6 columns-2 lg:flex lg:justify-center lg:space-x-12" aria-label="Footer">
          {navigation.main.map((item,index) => (
            <a key={""+index} href={item.href} className="group w-min mx-auto mb-6 flex gap-x-2 peer bg-accent cursor-pointer transition-all px-2 py-1 rounded-lg">
              <item.icon className={"group-hover:fill-emerald-500 transition-all"}/>
              <p className="truncate font-mono text-sm leading-6 text-gray-600 hover:text-gray-900">
                {item.name}
              </p>
            </a>
          ))}
        </nav>
        <p className="font-mono my-10 text-center text-xs leading-5 text-gray-500">
          Designed, Developed, and made with Love.
          <br/>
          &copy; 2024 uploadWorks, operated by Narro Project Organization.
        </p>
      </div>
    </footer>
  )
}
