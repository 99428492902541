"use client"

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import {useState} from 'react'

const faq = [
    {
        question: "What is uploadWorks?",
        answer: "uploadWorks is a platform designed to make uploading, storing, and delivering files easier for developers. Simply put, you can use uploadWorks for anything from seo images to user-specific profile pictures and banners - and it scales infinitely and cheaply. "
    },
    {
        question: "What does uploadWorks cost?",
        answer: "We charge for once for access to our platform (one-time), and then for monthly usage. Monthly usage is charged every month and depends on your usage. If you bring-your-own-bucket (BYOB), meaning you supply the S3 or R2 bucket, you will not be charged usage fees for uploadWorks, but you may have fees on the platform of your bucket."
    },
    {
        question: "Can I use uploadWorks without paying for access?",
        answer: "The one-time payment for access is required to use uploadWorks. This helps us provide for our platform, and is only charged once. Afterwards you can use uploadWorks for as long as you want, and you're only charged on what you use."
    },
    {
        question: "What is BYOB?",
        answer: "Bring-Your-Own-Bucket allows you to bring your own S3 or R2 bucket, meaning we will not charge you for usage fees. You may still have charges from the platform of your bucket. BYOB currently doesn't support metrics."
    },
    {
        question: "What's your refund policy?",
        answer: "If you don't like uploadWorks, let us know within 24 hours via email (help@upload.work) and we'll send you a full refund, excluding any monthly usage fees."
    },
    {
        question: "Can I pay using PayPal?",
        answer: "Short answer - yes; although you will need to pre-pay for usage. Please email us at help@upload.works."
    },
    {
        question: "What files can I upload?",
        answer: "We allow uploading any type of file, and as the developer you get to choose which files can be uploaded. Your bucket could be used for images, as a pdf host, or even for audiobooks. We won't tell you what to store."
    },
]

export default function IndexFAQ() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div id={"faq"} className={"mt-20 gap-y-32 grid"}>
            <div className={"max-w-2xl"}>
                <p className={"text-3xl md:text-4xl font-mono font-bold"}>Questions? Answers.</p>
                <Accordion type="single" collapsible>
                    {faq.map((value,index) => (
                    <AccordionItem value={""+index} key={""+index} className={"mt-4"}>
                        <AccordionTrigger>
                            <p className={"font-bold text-lg text-left"}>{value.question}</p>
                        </AccordionTrigger>
                        <AccordionContent>
                            <p className={"font-normal"}>{value.answer}</p>
                        </AccordionContent>
                    </AccordionItem>
                        ))}
                </Accordion>
            </div>
        </div>
        )
}
